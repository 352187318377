<template>
  <div id="app">
    <notification />
    <div v-if="$store.state.formSubmitting" class="loading">
      <div class="icon">
        <font-awesome-icon
          :icon="['fas', 'circle-notch']"
          class="loading"
          pulse
          size="6x"
        ></font-awesome-icon>
      </div>
    </div>
    <div class="container row middle-xs center-xs">
      <div class="col-md-10">
        <div class="app-header row between-xs top-bar">
          <div class="col-xs col-xs-offset-1 p0">
            <a href="/">
              <img :src="`/assets/img/logo.png`" class="logo" />
            </a>
          </div>
          <div class="col-xs">
            <div v-if="isAuthenticated" class="nav-right">
              <ul class="row end-xs nav-icons middle-xs">
                <li>
                  <a href="/segments">
                    <font-awesome-icon
                      :icon="['far', 'file-upload']"
                    ></font-awesome-icon
                  ></a>
                </li>
                <li>
                  <a href="/logout">
                    <font-awesome-icon
                      :icon="['fa', 'sign-out']"
                    ></font-awesome-icon>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row middle-xs">
          <div class="col-xs-12 router-view">
            <router-view />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const Notification = () => import("@/components/elements/Notification");
import { mapState, mapGetters } from "vuex";
export default {
  components: {
    Notification
  },
  computed: {
    ...mapState({
      token: state => state.authentication.token
    }),
    ...mapGetters({
      isAuthenticated: "authentication/isAuthenticated"
    }),
    year() {
      return new Date().getFullYear();
    }
  },

  watch: {
    isAuthenticated(val) {
      if (val) document.body.classList.add("bg-image");
      else document.body.classList.remove("bg-image");
    }
  },

  created() {
    const token = localStorage.getItem("token");

    if (token === null) {
      return this.$router.push("/login").catch(err => {});
    }

    this.$store.dispatch("authentication/decodeToken", token).then(() => {
      if (this.isAuthenticated) document.body.classList.add("bg-image");
      else document.body.classList.remove("bg-image");
    });
  },
  mounted() {
    if (this.isAuthenticated) document.body.classList.add("bg-image");
    else document.body.classList.remove("bg-image");
  }
};
</script>
<style>
@import "https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700,900";
</style>

<style lang="scss">
#app {
  color: #2c3e50;

  font-family: "Source Sans Pro", sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#nav {
  padding: 30px;

  a {
    color: #2c3e50;

    font-weight: bold;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.loading {
  position: absolute;
  z-index: 2;

  width: 100%;
  height: 100%;
  .icon {
    position: absolute;
    top: calc(50% - 72px);
    left: calc(50% - 72px);

    width: 72px;
    height: 72px;
  }
}

.app-header {
  // margin: 0 0 10px;
  margin-top: 40px;
  padding-bottom: 20px;

  &.top-bar {
    margin: 0;

    .logo {
      display: block;

      width: auto;
      height: 35px;
      margin-top: 10px;
    }
  }

  .nav-right {
    margin: 0;

    .nav-icons {
      list-style: none;

      li {
        display: inline-block;

        width: 70px;
        height: 62px;

        cursor: pointer;
        transition: all 0.2s linear;
        text-align: center;

        border-left: 1px solid #eee;

        svg {
          height: 62px;

          cursor: pointer;

          color: #666;

          font-size: 20px;
        }

        &:hover {
          background-color: $primary;
        }
      }
    }
  }

  .bottom-bar {
    z-index: 1;

    overflow: auto;

    padding: 20px 40px;

    background-color: $primary;
    .subhead {
      text-transform: uppercase;

      font-size: 16px;
    }
  }
}
</style>
