import axios from "axios";
import jwt_decode from "jwt-decode";

const getDefaultUser = () => ({
  email: null,
  id: null,
  username: null,
  first_name: null,
  last_name: null
});
const getDefaultLogin = () => ({
  email: null,
  password: null
});
const getDefaultState = () => ({
  login: getDefaultLogin(),
  user: getDefaultUser(),
  accounts: [],
  exp: 0,
  iat: 0,
  token: null
});

const state = getDefaultState();
const getters = {
  //TODO::check for valid token as well as null
  isAuthenticated: state =>
    state.token !== null &&
    state.exp >= Math.floor(new Date().getTime() / 1000),
  isTokenExpired: state => state.exp >= Math.floor(new Date().getTime() / 1000),

  isInternal: state => {
    //if digital remedy, adready, cpxi, or cpxi interactive in and of the accounts
  },
  isAdmin: state => false,
  token: state => state.token
};
const mutations = {
  SET_USER(state, user) {
    state.user = user;
  },
  SET_ACCOUNTS(state, accounts) {
    state.accounts = accounts;
  },
  SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_PROP(state, { prop, value }) {
    state[prop] = value;
  },
  SET_USER_PROP(state, { prop, value }) {
    state.user[prop] = value;
  },
  SET_AUTHENTICATION_PROP(state, { key, prop, value }) {
    state[key][prop] = value;
  },
  RESET_TOKEN(state) {
    state.token = null;
  },
  RESET_USER(state) {
    state.user = getDefaultUser();
  }
};
const actions = {
  async login({ state, dispatch, commit }) {
    await axios({
      method: "post",
      url: `${process.env.VUE_APP_AUTH_URL}/login`,
      data: {
        email: state.login.email,
        password: state.login.password
      }
    })
      .then(response => {
        dispatch("decodeToken", response.data.response.data.token);
      })
      .catch(error => {
        commit(
          "SET_NOTIFICATION_PROPS",
          {
            messages: [error.response.data.response.message],
            title: "Error!",
            style: "alert",
            show: true
          },
          { root: true }
        );
      });
  },

  logout({ commit }) {
    return new Promise((resolve, reject) => {
      commit("RESET_USER");
      commit("RESET_TOKEN");

      commit("RESET_NOTIFICATION", null, {
        root: true
      });
      resolve();
    });
  },
  decodeToken({ commit, getters, dispatch, state }, token) {
    return new Promise((resolve, reject) => {
      if (getters.isAuthenticated) return resolve();
      if (token === null) return reject();

      let decoded = jwt_decode(token);
      commit("SET_TOKEN", token);
      commit("SET_USER", decoded.user);
      commit("SET_ACCOUNTS", decoded.accounts);
      commit("SET_PROP", { prop: "iat", value: decoded.iat });
      commit("SET_PROP", { prop: "exp", value: decoded.exp });

      localStorage.setItem("token", token);
      resolve();
    });
  },
  register() {},
  forgotPassword() {},
  resetPassword() {}
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
