import Vue from "vue";
import App from "./App.vue";
import VButton from "./components/elements/Button";
import router from "./router";
import "./utils/fonts";
import store from "./store";
import axios from "axios";

Vue.config.productionTip = false;

Vue.prototype.$http = axios;
let token = localStorage.getItem("token");
Vue.prototype.$http.interceptors.request.use(
  config => {
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config;
  },

  error => {
    return Promise.reject(error);
  }
);

router.beforeEach((to, from, next) => {
  if (!to.matched.some(record => record.meta.requiresAuth)) {
    next();
  } else {
    let isAuthenticated = store.getters["authentication/isAuthenticated"];

    //if the user is not authenticated but a token was fetched from localstorage
    //save the token to the store and get the value of isAuthenticated again
    if (!isAuthenticated && token !== null) {
      store.dispatch("authentication/decodeToken", token);
      isAuthenticated = store.getters["authentication/isAuthenticated"];
    }
    if (!isAuthenticated) {
      next("/login");
      return;
    }

    next();
  }
});

Vue.component("VButton", VButton);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
