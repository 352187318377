<template>
  <div class="segments main-content row">
    <div class="col-md-11 col-md-offset-1 p0">
      <div class="row">
        <h1 class="col-xs-8">IP Segment Uploader</h1>
      </div>
      <div class="row">
        <div class="col-xs-7">
          <div class="row">
            <div class="col-xs-6">
              <label for="segment_name" name="segment_name"
                >Segment Name*
                <input v-model="form.segment_name" type="text" />
              </label>
            </div>
            <div class="col-xs-6">
              <label for="segment_name" name="segment_name"
                >Code
                <input v-model="form.code" type="text" />
              </label>
            </div>
          </div>
          <div class="row center-xs">
            <label for="file"
              >Segment File
              <input id="file" ref="file" type="file" @change="fileAdded" />
            </label>
          </div>
          <div v-if="false" class="row center-xs">
            <label for="file" class="col-xs-8"
              >IP Segment File
              <file-upload
                :upload-url="uploadUrl"
                :chunk-size="10000"
                @dr-file-upload-success="onFileUpload"
                @dr-file-added="fileAdded"
              />
            </label>
          </div>
          <div class="row">
            <v-button type="submit" :size="6" :event="onSubmit"
              >Submit
            </v-button>
          </div>
          <div v-show="job_id !== null" class="row message start-xs">
            <div class="title col-xs-12">
              You can view the status of the job
              <a :href="`/segments/status?job_id=${job_id}`">here.</a>
            </div>
          </div>
        </div>
        <div class="col-xs-4 col-xs-offset-1 sidebar start-xs">
          <ul>
            <li>Only Segment Name and File are required</li>
            <li>
              Job Status will populate after file has been uploaded
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
const FileUpload = () => import("@/components/elements/FileUpload/FileUpload");
import axios from "axios";
function form() {
  return {
    segment_name: null,
    code: null,
    file: null
  };
}
export default {
  components: { FileUpload },
  data() {
    return {
      form: form(),
      job_id: null,
      retry_timeout: 15000,
      retry: true,
      response: { message: null, failed: null, success: null, total: null }
    };
  },
  computed: {
    uploadUrl() {
      return `${process.env.VUE_APP_SEGMENT_UPLOAD_URL}/upload`;
    },
    message() {
      return ` <a href="/segments/status?job_id=${this.job_id}">here</a>`;
    }
  },

  methods: {
    fileAdded() {
      this.form.file = this.$refs.file.files[0];
    },
    onSubmit() {
      this.$store.commit("SET_FORM_SUBMITTING", true);
      let formData = new FormData();
      formData.append("code", this.form.code);
      formData.append("segment_name", this.form.segment_name);
      formData.append("file", this.form.file, this.form.file.name);
      formData.append("email", this.$store.state.authentication.user.email);

      axios({
        method: "post",
        url: `${process.env.VUE_APP_SEGMENT_UPLOAD_URL}/upload`,
        data: formData,
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        config: {
          headers: {
            "Content-Type": `multipart/form-data;
          boundary=${formData._boundary}`
          }
        }
      })
        .then(res => {
          this.form = form();
          document.getElementById("file").value = null;
          this.job_id = res.data.job_id;

          this.$store.commit("SET_NOTIFICATION_PROPS", {
            messages: [
              "Test Page has been created!",
              "An email will be sent to your account with the details"
            ],
            title: "Success!",
            style: "success",
            show: true
          });
        })
        .then(() => {
          this.$store.commit("SET_FORM_SUBMITTING", true);
        });
    }
  }
};
</script>
<style lang="scss" scoped>
.segments {
  padding-bottom: 25px;
}
h1 {
  margin-bottom: 20px;
}
.message {
  a {
    display: inline;

    text-decoration: underline;

    color: $secondary;
  }
}
.sidebar {
  font-size: 14px;
  ul {
    list-style-type: circle;
  }
}
</style>
