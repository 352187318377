<template>
  <div class="segments main-content row">
    <div class="col-md-10 col-md-offset-1 p0">
      <div class="row">
        <h1 class="col-xs">IP Segment Status</h1>
      </div>
      <div class="row middle-xs">
        <div class="col-xs-11">
          <label for="job_id">
            <input v-model="job_id" type="text" name="job_id" />
          </label>
        </div>
        <div class="col-xs-1">
          <button @click="fetchJob">
            <font-awesome-icon
              v-if="!loading"
              :icon="['fal', 'sync']"
            ></font-awesome-icon>
            <font-awesome-icon
              v-if="loading"
              :icon="['fal', 'sync']"
              pulse
            ></font-awesome-icon>
          </button>
        </div>
        <div class="row">
          <div v-show="job_id !== null" class="row response start-xs">
            <div class="title col-xs-12">IP Segment Upload Job Status:</div>
            <div class="col-xs-12 info">
              Message: <span>{{ response.message }}</span>
            </div>
            <div class="col-xs-12 info">
              Total: <span>{{ response.total }}</span>
            </div>
            <div class="col-xs-12 info">
              Success: <span>{{ response.success }}</span>
            </div>
            <div class="col-xs-12 info">
              Failed: <span>{{ response.failed }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      job_id: null,
      response: { message: null, failed: null, success: null, total: null },
      loading: false
    };
  },
  mounted() {
    let uuidRegex = new RegExp(
      "[0-9a-fA-F]{8}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{12}"
    );
    this.job_id = this.$route.query.job_id || null;
    if (uuidRegex.test(this.job_id)) {
      this.fetchJob();
    }
  },
  methods: {
    fetchJob() {
      this.loading = true;
      axios({
        method: "get",
        url: `${process.env.VUE_APP_SEGMENT_UPLOAD_URL}/upload?job_id=${this.job_id}`,
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
      }).then(response => {
        let data = response.data;

        this.statusArr = data.status.split("_");
        this.statusArr.pop();

        this.response.message = this.statusArr.join(" ");
        this.response.failed = data.failed;
        this.response.total = data.total;
        this.response.success = data.total - data.failed;
        this.loading = false;
      });
    }
  }
};
</script>
<style lang="scss" scoped>
button {
  font-size: 25px;
  padding-bottom: 25px;
  outline: none;
}
.segments {
  padding-bottom: 25px;
}
.response {
  .info {
    font-weight: bold;
    line-height: 27px;
    span {
      font-weight: normal;
    }
  }
}
</style>
