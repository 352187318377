import Vue from "vue";

// font-awesome
import {
  FontAwesomeIcon,
  FontAwesomeLayers,
  FontAwesomeLayersText
} from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";

import {
  faSignOut as falSignOut,
  faUser as falUser,
  faInfoCircle as falInfoCircle,
  faChevronRight as falChevronRight,
  faChevronDown as falChevronDown,
  faSync
} from "@fortawesome/pro-light-svg-icons";

import {
  faSignOut as fasSignOut,
  faUser as fasUser,
  faInfoCircle as fasInfoCircle,
  faChevronRight as fasChevronRight,
  faChevronDown as fasChevronDown,
  faCloudUpload,
  faCheck,
  faTimes,
  faExclamation,
  faChartPie,
  faCircleNotch,
  faEdit
} from "@fortawesome/pro-solid-svg-icons";

import {
  faSignOut,
  faUser,
  faInfoCircle,
  faChevronRight,
  faChevronDown,
  faFileUpload
} from "@fortawesome/pro-regular-svg-icons";

// configure
library.add(
  falSignOut,
  falUser,
  faSignOut,
  faUser,
  fasSignOut,
  fasUser,
  falInfoCircle,
  faInfoCircle,
  fasInfoCircle,
  falChevronRight,
  falChevronDown,
  fasChevronRight,
  fasChevronDown,
  faChevronRight,
  faChevronDown,
  faCloudUpload,
  faCheck,
  faTimes,
  faExclamation,
  faChartPie,
  faCircleNotch,
  faEdit,
  faFileUpload,
  faSync
);

Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("font-awesome-layers", FontAwesomeLayers);
Vue.component("font-awesome-layers-text", FontAwesomeLayersText);
