<template>
  <div class="auth-container row middle-xs center-xs">
    <div class="col-xs-10 auth-form">
      <div class="row">
        <div class="col-xs-6 p0">
          <img :src="`/assets/img/square.png`" class="logo" />
        </div>
        <div class="col-xs input-box">
          <div class="row">
            <span class="title">Login</span>
          </div>
          <div class="row">
            <input
              type="email"
              name="email"
              placeholder="Email"
              @input="setLoginProp"
            />
          </div>
          <div class="row">
            <input
              type="password"
              name="password"
              placeholder="Password"
              @input="setLoginProp"
            />
          </div>
          <div class="row">
            <v-button
              :outline="true"
              :size="6"
              :event="submitLogin"
              :show-loading="showLoading"
              :disabled="disabled"
              >Login</v-button
            >
          </div>
          <div class="row middle-xs">
            <div class="col-xs-6">
              <label for="remember">
                <input type="checkbox" />
                Remember Me
              </label>
            </div>
            <div class="col-xs-6">
              Forgot Password
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState, mapActions, mapMutations } from "vuex";

export default {
  data() {
    return {
      showLoading: false,
      disabled: false
    };
  },
  computed: {
    ...mapState({
      email: state => state.authentication.login.email,
      password: state => state.authentication.login.password
    }),
    ...mapGetters({
      isAuthenticated: "authentication/isAuthenticated"
    })
  },
  watch: {
    isAuthenticated(val) {
      if (val) return this.$router.push("/");
    }
  },
  mounted() {
    if (this.isAuthenticated) {
      this.$router.push("/");
    }
  },
  methods: {
    ...mapMutations({
      setAuthProp: "authentication/SET_AUTHENTICATION_PROP"
    }),
    ...mapActions({
      login: "authentication/login"
    }),
    setLoginProp($event) {
      this.setAuthProp({
        key: "login",
        prop: $event.target.name,
        value: $event.target.value
      });
    },
    async submitLogin() {
      this.showLoading = true;
      this.disabled = true;
      await this.login();
      this.showLoading = false;
      this.disabled = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.yellow {
  height: 380px;

  background-color: $primary;
}
.auth-form {
  padding: 0;

  box-shadow: 0px 0px 30px 15px #f5f5f5f5;
}
.input-box {
  padding: 30px;
  .title {
    margin-top: 10px;
    margin-bottom: 30px;
  }
  .button {
    margin-top: 0;
    margin-bottom: 30px;
  }
}
input[type="checkbox"] {
  position: relative;
  top: -3px;
}
</style>
