import Vue from "vue";
import Vuex from "vuex";
import authentication from "./modules/authentication";
Vue.use(Vuex);
const getDefaultNotification = () => ({
  messages: [],
  title: "",
  style: "success", //can be success, info, alert
  show: false
});
const getDefaultState = () => ({
  view: {
    notification: getDefaultNotification()
  },
  formSubmitting: false
});
export default new Vuex.Store({
  state: getDefaultState(),
  mutations: {
    RESET_NOTIFICATION(state) {
      state.view.notification = getDefaultNotification();
    },
    SET_NOTIFICATION_PROP(state, { prop, value }) {
      state.view.notification[prop] = value;
    },
    SET_NOTIFICATION_PROPS(state, notification) {
      state.view.notification = notification;
    },
    SET_FORM_SUBMITTING(state, isSubmitting) {
      state.formSubmitting = isSubmitting;
    }
  },
  actions: {},
  modules: { authentication }
});
