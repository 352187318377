import Vue from "vue";
import VueRouter from "vue-router";
import Logout from "../pages/Logout";
import Home from "../pages/Home";
import Login from "../pages/Login";
import Segments from "../pages/Segments";
import Status from "../pages/Status";
Vue.use(VueRouter);

const routes = [
  { path: "/login", name: "login", component: Login },
  { path: "/logout", name: "logout", component: Logout },
  {
    path: "/",
    name: "home",
    component: Home,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/segments",
    name: "segments",
    component: Segments,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/segments/status",
    name: "segments-job-status",
    component: Status,
    meta: {
      requiresAuth: true
    }
  }
];

const router = new VueRouter({
  mode: "history",
  routes
});

export default router;
